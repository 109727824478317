import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';

const FileStatusCell = ({ completedFields, isNaturalPersonRut }) => {
  const totalFields = isNaturalPersonRut ? 10 : 11;
  return (
    <Stack direction="row" alignItems="center" justifyContent="center" spacing={1}>
      <Box sx={{ position: 'relative', display: 'inline-flex' }}>
        <CircularProgress variant="determinate" value={(completedFields / totalFields) * 100} />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography variant="footerText">{completedFields}/{totalFields}</Typography>
        </Box>
      </Box>
      <Typography variant="body2">Completado</Typography>
    </Stack>
  );
};

FileStatusCell.propTypes = {
  completedFields: PropTypes.number.isRequired,
  isNaturalPersonRut: PropTypes.bool.isRequired,
};

export default FileStatusCell;
