import ControlledSelectMenu from '@fingo/lib/components/menus/ControlledSelectMenu';
import Box from '@mui/material/Box';
import React from 'react';

const DebtorControlledSelectMenu = ({
  options,
  value,
  setValue,
  onChange,
}) => (
  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
    <ControlledSelectMenu
      options={options}
      value={value}
      setValue={setValue}
      onChange={onChange}
    />
  </Box>
);

DebtorControlledSelectMenu.propTypes = {
  ...ControlledSelectMenu.propTypes,
};

DebtorControlledSelectMenu.defaultProps = {
  ...ControlledSelectMenu.defaultProps,
};

export default DebtorControlledSelectMenu;
