import React from 'react';
import propTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

const RequiredFieldsList = ({ title, fields }) => (
  <Box sx={{ p: 0.5 }}>
    <Typography variant="body2" sx={{ mb: 0.5 }}>
      {title}
    </Typography>
    <List
      dense
      sx={{
        p: 0,
        m: 0,
        '& .MuiListItem-root': {
          p: 0,
          pl: 2,
          minHeight: '20px',
          position: 'relative',
          '&::before': {
            content: '"•"',
            position: 'absolute',
            left: 0,
            color: 'white',
          },
        },
      }}
    >
      {fields.map((field) => (
        <ListItem key={field}>
          <ListItemText
            primary={field}
            primaryTypographyProps={{
              variant: 'body2',
              sx: { color: 'white' },
            }}
          />
        </ListItem>
      ))}
    </List>
  </Box>
);

RequiredFieldsList.propTypes = {
  title: propTypes.string.isRequired,
  fields: propTypes.arrayOf(propTypes.string).isRequired,
};

export default RequiredFieldsList;
