import React, { useCallback } from 'react';
import propTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { styled } from '@mui/material/styles';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const FileUploadBox = ({
  selectedFile,
  onFileChange,
  acceptedFiles,
  buttonText,
}) => {
  const handleDrop = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();

    const { files } = event.dataTransfer;
    if (files.length > 0) {
      onFileChange({ target: { files: [files[0]] } });
    }
  }, [onFileChange]);

  const handleDragOver = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
  }, []);

  return (
    <Box
      component="label"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        p: 3,
        border: '2px dashed',
        borderColor: 'grey.400',
        borderRadius: 2,
        bgcolor: 'grey.50',
        cursor: 'pointer',
        transition: 'all 0.2s ease-in-out',
        minHeight: 150,
        '&:hover': {
          borderColor: 'grey.500',
          bgcolor: 'grey.100',
        },
      }}
      onDrop={handleDrop}
      onDragOver={handleDragOver}
    >
      <CloudUploadIcon
        sx={{
          fontSize: 40,
          color: 'primary.main',
          mb: 1,
        }}
      />
      <Typography variant="h6" color="primary.main" gutterBottom>
        {buttonText}
      </Typography>
      <Typography variant="body2" color="text.secondary" align="center">
        Arrastra y suelta archivos aquí o haz clic para seleccionar
      </Typography>
      <VisuallyHiddenInput
        type="file"
        onChange={onFileChange}
        accept={acceptedFiles}
      />
      {selectedFile && (
        <Typography variant="body2" mt={2} color="primary.main" align="center">
          Archivo seleccionado: {selectedFile.name}
        </Typography>
      )}
    </Box>
  );
};

FileUploadBox.propTypes = {
  selectedFile: propTypes.shape({
    name: propTypes.string.isRequired,
    type: propTypes.string.isRequired,
    size: propTypes.number.isRequired,
  }),
  onFileChange: propTypes.func.isRequired,
  acceptedFiles: propTypes.string,
  buttonText: propTypes.string,
};

FileUploadBox.defaultProps = {
  selectedFile: null,
  acceptedFiles: '.csv,.xlsx,.xls',
  buttonText: 'Subir archivo',
};

export default FileUploadBox;
